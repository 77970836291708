<template>
  <div class="container-fluid pb-5">
    <div class="page-header mb-1">
      <strong>ประวัติการเข้าใช้งาน</strong>
    </div>

    <AccountLogReport :accountId="null" />
  </div>
</template>
<script>
import AccountLogReport from '@/views/account-report/components/AccountLogReport'

export default {
  name: 'AccountLogs',
  components: {
    AccountLogReport
  }
}
</script>
